import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useLocation, useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import ExcelUpload from "../../utils/components/excelUpload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  // {
  //   id: "instrumentName",
  //   label: "Instrument Name",
  //   align: "left",
  //   minWidth: 40,
  // },
  // ,
  {
    id: "vendor_name",
    label: "Vendor Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const InstrumentInOutList = () => {
  const [page, setPage] = React.useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Certificate Number");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const navigate = useNavigate();
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const [searched, setSearched] = React.useState(false);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        // "Instrument Name": "instrument.name",
        "Certificate Number": "vm.certificateNumber",
        "Vendor Name": "vm.name",
        // Model: "modelNo",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT io.*, vm.name AS vendor_name, vm.address, vm.certificateNumber FROM instrumentInOut io  JOIN vendor_master vm ON io.vendorId = vm.id WHERE io.id IN (SELECT MAX(id) FROM instrumentInOut GROUP BY vendorId) ${whereConditions} ORDER BY io.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM instrumentInOut io  JOIN vendor_master vm ON io.vendorId = vm.id WHERE io.id IN (SELECT MAX(id) FROM instrumentInOut GROUP BY vendorId) ${whereConditions} ORDER BY io.id DESC`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);    
    setSearched(true);
    fetchFinalData();
    getTotalRows();
  };

  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched");
    sessionStorage.removeItem("currentPath");
    setSearchBy("Certificate Number");
    setSearchKey("");
    setSearched(false); 
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    let SQlQuery={
      "query":`DELETE FROM instrumentInOut WHERE vendorId = ${id}`
    }
    axiosWithToken
      .post(url + `dynamic`,SQlQuery)
      .then((res) => {
        toast("Vendor's all instrument deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (searched==false) {
      fetchFinalData();
      getTotalRows();
    }
  }, [searched]);

  useEffect(() => {
    fetchFinalData();
    getTotalRows();
  }, [rowsPerPage, page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit Instrument",
      // link: true,
      handler: (row) => navigate(`/editInstrumentInOut/${row.vendorId}`),
      icon: <PreviewIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete Instrument",
      disabled:!(editAccess?.includes(2) || editAccess?.includes(0)) ? true : false,
      icon: (
        <DeleteIcon
          style={{
            color: !(editAccess?.includes(2) || editAccess?.includes(0))
              ? "lightgray"
              : "#dc3545",
          }}
        />
      ),
      handler: (row) =>
        window.confirm("Are you sure you want to delete this?") &&
        handleDelete(row?.vendorId),
    },
  ];

  const ExcelModal = () => {
    const [open, setOpen] = React.useState(false);
    const [excelArray, setExcelArray] = React.useState([]);
    const [instruments, setInstruments] = React.useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fetchInstrumentsData = () => {
      let url = BASE_URL;
      axiosWithToken
        .get(url + "instrument?_fields=id,name")
        .then((res) => {
          setInstruments(res.data);
        })
        .catch((err) => console.log("instruments data error: ", err));
    };

    // Arrays to hold rejected objects and remaining objects
    const rejectedData = [];
    const remainingData = [];

    // Check for clientId and instrumentId
    for (const excelObj of excelArray) {
      const foundInstrument = instruments.find(
        (instrument) => instrument.id === excelObj.instrumentId
      );

      if (!foundInstrument) {
        const rejectedObj = {
          ...excelObj,
          rejectionStatus: "Instrument is not found in Instrument database",
        };
        rejectedData.push(rejectedObj);
      } else {
        remainingData.push(excelObj);
      }
    }

    var refresh = () => {
      window.location.reload(false);
    };

    const handleSubmit = () => {
      axiosWithToken
        .post(BASE_URL + "instrumentInOut/bulk", remainingData)
        .then((res) => {
          setTimeout(refresh, 500);
          toast.success("Excel data uploaded Successfully!");
        })
        .catch((error) => {
          toast.error("Something Went Wrong In instrumentInOut!");
        });
    };

    useEffect(() => {
      fetchInstrumentsData();
    }, []);
    return (
      <div style={{ marginLeft: "15px" }}>
        {/* <Button
        id="ducMaster_list_uploadexcel"
          variant="contained"
          // size="small"
          onClick={handleOpen}
        >
          Upload Excel File
        </Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              sx={{ textAlign: "center" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Upload Excel for Instrument Master
            </Typography>

            <Typography
              sx={{ textAlign: "right", fontSize: "15px" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {`Rejected Data:${rejectedData.length}, Accepted Data:${remainingData.length}`}
            </Typography>

            <Box style={{ margin: "auto" }}>
              <ExcelUpload
                setExcelArray={setExcelArray}
                buttonName={"Select Excel File"}
                isDUCMaster={true}
              />
            </Box>

            <Typography
              sx={{ textAlign: "center", margin: "15px 0px" }}
              id="modal-modal-title"
              variant="h6"
              component="h6"
            >
              Rejected Excel Data
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Rejected Data Table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.</TableCell>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Instrument ID</TableCell>
                    <TableCell>DUCID</TableCell>
                    <TableCell>Rejection Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rejectedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.clientId}</TableCell>
                      <TableCell>{row.instrumentId}</TableCell>
                      <TableCell>{row.DUCID}</TableCell>
                      <TableCell>{row.rejectionStatus}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              style={{
                margin: "20px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                component="span"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                component="span"
                disabled={!remainingData.length > 0}
                onClick={handleSubmit}
              >
                continue
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 1, px:1  }}>
        <Toolbar style={{padding:0}}>
          {showComponent("add") && (
            <Button
              variant="contained"
              size="small"
              component={Link}
              to="/createInstrumentInOut"
              // onClick={() => {
              //   setTitle("Master/ add new user");
              // }}
            >
              <b>ADD NEW</b>
            </Button>
          )}
        </Toolbar>
        <Grid
          container
          spacing={2}
          justifyContent={ "flex-start"}
          alignItems="center"
          style={{ padding: "5px 5px" }}
        >
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
              }}
              size="small"
              id="combo-box-demo"
              options={
                _userType != 3
                  ? [
                      // { key: "name", label: "Instrument Name" },
                      {
                        key: "identificationNumber",
                        label: "Identification Number",
                      },
                      { key: "serialNumber", label: "Serial Number" },
                      { key: "modelNo", label: "Model" },
                    ]
                  : [
                      { key: "name", label: "Instrument Name" },
                      { key: "modelNo", label: "Model" },
                    ]
              }
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                setSearched(false);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              inputProps={{
                autoComplete: 'off',
              }}
              variant="outlined"
              onChange={(e) => {
                setSearchKey(e.target.value);
                setSearched(false);
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={1} lg={1} textAlign={"left"}>
            <Button
              variant="contained"
              size="small"
              sx={{ height : "40px" }}
              color={searched ? "error" : "primary"}
              onClick={() => {
                searched ? clearSearch() : search();
              }}
            >
              {searched ? <RestartAltIcon /> : <SearchIcon />}
            </Button>
          </Grid>
          {/* <Grid item xs={8} sm={8} md={2} lg={2}>
            {ExcelModal()}
          </Grid> */}
        </Grid>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
};

export default InstrumentInOutList;
