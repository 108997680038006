import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Paper } from "@mui/material";
import Table from "react-bootstrap/Table";
import { BASE_URL } from "./../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import moment from "moment";

export default function TodaysSceduler() {
  const [schedulerList, setschedulerList] = useState([]);

  const getStandardOut = (event) => {
    let sqlQuery = {
      query:
        "SELECT calibration_register.*, instrument.name AS instrumentName, plant_master.location AS plantLocation, lab_master.name AS labName, instrument_master.make, instrument_master.modelNo, instrument_master.serialNumber, instrument_master.identificationNumber AS identificationNo, REPLACE(REPLACE(REPLACE(instrument_master.instrumentRange, '#', ''), '||', ','), '|', ' to ') AS instrumentRange, REPLACE(REPLACE(REPLACE(instrument_master.leastCount, '#', ''), '||', ','), '|', ' to ') AS leastCount, REPLACE(REPLACE(REPLACE(instrument_master.accuracy, '#', ''), '||', ','), '|', ' to ') AS accuracy FROM calibration_register LEFT JOIN instrument_master ON calibration_register.instrumentId = instrument_master.instrumentId LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id LEFT JOIN plant_master ON instrument_master.plantId = plant_master.id LEFT JOIN lab_master ON instrument_master.labId = lab_master.id WHERE DATE(calibration_register.dueDate) = CURDATE()",
    };
    let url = BASE_URL;
    axiosWithToken
      .post(url + "dynamic", sqlQuery)
      .then((res) => {
        setschedulerList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

//   console.log(schedulerList);
  useEffect(() => {
    getStandardOut();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Instrument Name</th>
            <th>Identification Number</th>
            <th>Plant Location</th>
            <th>Least Count</th>
            <th>Accuracy</th>
            <th>Model Number</th>
            <th>Due Date</th>
          </tr>
        </thead>
        <tbody>
          {schedulerList?.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.instrumentName}</td>
              <td>{item.identificationNumber}</td>
              <td>{item.plantLocation}</td>
              <td>{item.leastCount}</td>
              <td>{item.accuracy}</td>
              <td>{item.modelNo}</td>
              <td>{moment(item.dueDate).format("YYYY-MM-DD")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Paper>
  );
}