import {
  Autocomplete,
  Paper,
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { ClassicTable } from "../../utils/components/Styles";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  ,
  {
    id: "instrumentIdentificationNumber",
    label: "Identification Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "outwordDate",
    label: "Outward Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "remarks",
    label: "Outward Remark",
    align: "left",
    minWidth: 40,
  },
  {
    id: "actualReturnDate",
    label: "Return Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "inwardRemark",
    label: "Inward Remark",
    align: "left",
    minWidth: 40,
  },
];

const EditInstrumentInOut = () => {
  const [data, setFinalData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const params = useParams();

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT instrumentInOut.*, instrument.name AS instrumentName, instrument_master.identificationNumber AS instrumentIdentificationNumber FROM instrumentInOut LEFT JOIN instrument_master ON instrument_master.id = instrumentInOut.instrumentMasterId LEFT JOIN instrument ON instrument.id = instrument_master.instrumentId WHERE instrumentInOut.vendorId = ${params?.id}  ${whereConditions}  ORDER BY instrumentInOut.id DESC`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM instrumentInOut LEFT JOIN instrument ON instrumentInOut.instrumentId = instrument.id ${whereConditions}`;
    return data;
  };

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  const handleChange = (value, index, fieldName) => {
    const updatedData = data.map((row, idx) => {
      if (idx === index) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    setFinalData(updatedData);
  };

  const handleSubmit = () => {
    const url = BASE_URL;
    data.forEach((item) => {
      const { instrumentName,instrumentIdentificationNumber, ...formattedItem } = {
        ...item,
        expectedReturnDate: moment(item.expectedReturnDate).format(
          "YYYY-MM-DD"
        ),
        actualReturnDate: item.actualReturnDate
          ? moment(item.actualReturnDate).format("YYYY-MM-DD")
          : null,
        lastModified: moment(item.lastModified).format("YYYY-MM-DD"),
        outwordDate: moment(item.outwordDate).format("YYYY-MM-DD"),
        actualReturnDate: item.actualReturnDate
          ? moment(item.actualReturnDate).format("YYYY-MM-DD")
          : null,
      };

      axiosWithToken
        .patch(`${url}instrumentInOut/${item.id}`, formattedItem)
        .then((res) => {
          //   toast("InstrumentInOut edit successfully!");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    });
    setTimeout(refresh, 500);
  };

  useEffect(() => {
    if (params?.id) fetchFinalData();
  }, [params?.id]);

  return (
    <div>
      <>
      <br/>

      {/* <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
      <TextField
              id="outlined-basic"
              label="Client"
              size="small"
              disabled
              value={companyName || ""}
              fullWidth
              variant="outlined"
            />
        </Grid>
        </Grid> */}
        <br />

        <h4 style={{ "margin-bottom": "15px" }}>Instrument Records</h4>
        <div style={{ width: "100%", overflow: "auto" }}>
          <ClassicTable>
            <Table sx={{ minWidth: 660 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {" "}
                      <Typography noWrap sx={{ fontSize: "14px" }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 &&
                  data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ fontSize: "12px" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ fontSize: "12px" }}>
                          {row?.instrumentName}
                        </TableCell>
                        <TableCell sx={{ fontSize: "12px" }}>
                          {row?.instrumentIdentificationNumber}
                        </TableCell>
                        <TableCell sx={{ fontSize: "12px" }}>
                          {row?.outwordDate
                            ? moment(row?.outwordDate).format("DD-MM-YYYY")
                            : " "}
                        </TableCell>
                        <TableCell sx={{ fontSize: "12px", minWidth: "200px" }}>
                          {row?.remarks}
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              slotProps={{ textField: { size: "small" } }}
                              inputFormat="dd/MM/yyyy"
                              format="dd/MM/yyyy"
                              value={
                                row.actualReturnDate
                                  ? new Date(row.actualReturnDate)
                                  : null
                              }
                              minDate={row?.outwordDate ? new Date(row.outwordDate) : null}
                              onChange={(newValue) =>
                                handleChange(
                                  newValue,
                                  index,
                                  "actualReturnDate"
                                )
                              }
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                            />
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell sx={{ fontSize: "12px", minWidth: "300px" }}>
                          <TextField
                            id="outlined-basic"
                            size="small"
                            rows={3}
                            multiline
                            maxRows={5}
                            fullWidth
                            variant="outlined"
                            value={row?.inwardRemark || ""}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                index,
                                "inwardRemark"
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </ClassicTable>

          <Toolbar style={{ padding: "50px", overflow: "auto" }}>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              size="small"
              disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
              sx={{ m: 0 }}
              onClick={handleSubmit}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </div>
      </>
    </div>
  );
};

export default EditInstrumentInOut;
