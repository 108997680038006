

export const showComponent = (operation) => {
    let _userType = localStorage.getItem("type");
    if (_userType == '1')
        return true
    else if (_userType == '2') {
        // if (operation === "delete") return false
        // else
         return true
    } else if (_userType == '3') {
        return true
        // if (operation === "print" || operation === "show") return true
        // else return false
    }
    return true
}


