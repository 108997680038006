import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Grid,
  useMediaQuery,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ExcelDownload from "../../utils/components/excelDownload";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "urlNumber",
    label: "ULR Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "labName",
    label: "Lab Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "plantLocation",
    label: "Plant Location",
    align: "left",
    minWidth: 40,
  },

  {
    id: "identificationNo",
    label: "Identification Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentRange",
    label: "Ranges",
    align: "left",
    minWidth: 40,
  },
  {
    id: "leastCount",
    label: "Least Count",
    align: "left",
    minWidth: 40,
  },
  {
    id: "accuracy",
    label: "Accuracy",
    minWidth: 40,
    align: "left",
  },
  {
    id: "make",
    label: "Make",
    align: "left",
    minWidth: 40,
  },
  {
    id: "modelNo",
    label: "Model Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "calibrationDate",
    label: "Calibration Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "dueDate",
    label: "DUE Date",
    align: "left",
    minWidth: 40,
  },
];

const notNeededColumn = [
  "id",
  "status",
  "instrumentId"
];

const SchedulerList = () => {
  const [page, setPage] = React.useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Instrument Name": "instrument.name",
        "Certificate Number": "calibration_register.certificateNumber",
        "ULR Number": "calibration_register.urlNumber",
        "Plant Location": "plant_master.location",
        "Lab Name": "lab_master.name",
        "Identification Number": "identificationNo",
        "Serial Number": "instrument_master.serialNumber",
      }[searchBy];
      whereConditions = `and ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` calibration_register.dueDate BETWEEN  '${moment(from).format(
        "YYYY-MM-DD"
      )}' AND '${moment(to).format("YYYY-MM-DD")}' `;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT calibration_register.id, calibration_register.dueDate, calibration_register.calibrationDate, calibration_register.certificateNumber, calibration_register.urlNumber, MAX(instrument.name) AS instrumentName, MAX(plant_master.location) AS plantLocation, MAX(lab_master.name) AS labName, MAX(instrument_master.make) AS make, MAX(instrument_master.modelNo) AS modelNo, MAX(instrument_master.serialNumber) AS serialNumber, MAX(instrument_master.identificationNumber) AS identificationNo, MAX(REPLACE(REPLACE(REPLACE(instrument_master.instrumentRange, '#', ''), '||', ','), '|', ' to ')) AS instrumentRange, MAX(REPLACE(REPLACE(REPLACE(instrument_master.leastCount, '#', ''), '||', ','), '|', ' to ')) AS leastCount, MAX(REPLACE(REPLACE(REPLACE(instrument_master.accuracy, '#', ''), '||', ','), '|', ' to ')) AS accuracy FROM calibration_register LEFT JOIN instrument_master ON calibration_register.instrumentId = instrument_master.instrumentId LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id LEFT JOIN plant_master ON instrument_master.plantId = plant_master.id LEFT JOIN lab_master ON instrument_master.labId = lab_master.id ${(to && from) ? '' : `WHERE calibration_register.dueDate IS NOT NULL AND DATE(calibration_register.dueDate) <= CURDATE()`} ${whereConditions} GROUP BY calibration_register.id, calibration_register.dueDate, calibration_register.calibrationDate, calibration_register.certificateNumber, calibration_register.urlNumber ORDER BY calibration_register.id DESC ${pagination_settings}`
    };
    
    
    

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM calibration_register LEFT JOIN instrument_master ON calibration_register.instrumentId = instrument_master.instrumentId LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id LEFT JOIN plant_master ON instrument_master.plantId = plant_master.id LEFT JOIN lab_master ON instrument_master.labId = lab_master.id ${(to && from) ? '' : `WHERE calibration_register.dueDate IS NOT NULL AND DATE(calibration_register.dueDate) <= CURDATE()`} ${whereConditions} GROUP BY calibration_register.id, calibration_register.dueDate, calibration_register.calibrationDate ORDER BY calibration_register.id DESC`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
   
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
        getTotalRows()
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const search = () => {
    fetchFinalData();
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `vendor_master/${id}`)
      .then((res) => {
        toast("Vendor is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  // useEffect(() => {
  //   getTotalRows();
  // }, []);

  useEffect(() => {
    fetchFinalData();
  }, [rowsPerPage,page]);

  const actions = [
    // {
    //   id: "delete",
    //   tooltip: "Delete Vendor",
    //   icon: <DeleteIcon style={{ color: "#dc3545" }} />,
    //   handler: (row)  => window.confirm('Are you sure you want to delete this?') && handleDelete(row?.id),
    // },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 1, px:1  }}>
        <Grid
        container
        spacing={2}
        justifyContent={"flex-start"} 
        alignItems="center"
      >
        <Grid item xs={6} sm={6} md={1.5} lg={1.5}
        >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
               slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={6} md={1.5} lg={1.5}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
               slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}
        >
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          id="combo-box-demo"
          options={
            _userType != 3
              ? [
                  { key: "instrument.name", label: "Instrument Name" },
                  { key: "calibration_register.certificateNumber", label: "Certificate Number" },
                  { key: "calibration_register.urlNumber", label: "ULR Number" },
                  { key: "plant_master.location", label: "Plant Location" },
                  { key: "lab_master.name", label: "Lab Name" },
                  { key: "identificationNo", label: "Identification Number" },
                  { key: "instrument_master.serialNumber", label: "Serial Number" },
                ]
              : [{ key: "instrument.name", label: "Instrument Name" }]
          }

          renderInput={(params) => (
            <TextField {...params} label="Search By" />
          )}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        </Grid>
        <Grid item xs={6} sm={6} md={1.5} lg={1.5}
        >
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          inputProps={{
            autoComplete: 'off',
          }}
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        </Grid>
        <Grid item xs={6} sm={6} md={0.7} lg={0.7} textAlign={"left"}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ height :"40px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} textAlign={"left"}
        >
        <ExcelDownload
        finalData={data}
        notNeededColumn={notNeededColumn}
      />
        </Grid>
        </Grid>

        <br />

        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
};

export default SchedulerList;
