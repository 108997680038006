import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import ExcelUpload from "../../utils/components/excelUpload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ExcelDownload from "../../utils/components/excelDownload";
import html2pdf from "html2pdf.js";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "name",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "identificationNumber",
    label: "Identification Number",
    align: "left",
    minWidth: 40,
  },

  {
    id: "outwordDate",
    label: "Outword Date",
    align: "left",
    minWidth: 40,
  },

  {
    id: "expectedReturnDate",
    label: "Expected Return Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "actualReturnDate",
    label: "Return Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "vendorName",
    label: "Vendor Name",
    align: "left",
    minWidth: 40,
  },
];

let notNeededColumn = [
  "id",
  "instrumentMasterId",
  "vendorId",
  "lastModified",
  "currentLocation",
  "userId",
  "status",
];

const InstrumentInOutReport = () => {
  const [inputStates, setInputStates] = React.useState({
    instrumentMasterId: null,
  });
  const [instrumentList, setInstrumentList] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [vendorMasterData, setVendorMasterData] = React.useState([]);
  const printComponentRef = React.useRef();
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [isPrint, setIsPrint] = React.useState(false);
  const [instrumentCount, setInstrumentCount] = React.useState([]);

  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  function getInstrumentData() {
    let SQLQuery = {
      query: `SELECT instrument_master.*, instrument.name FROM instrument_master LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id ${
        inputStates.VendorId && inputStates.VendorId.length > 0
          ? `WHERE assignedUser IN (${inputStates.VendorId.join(", ")})`
          : ""
      }`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        let newData = res.data;
        setInstrumentList(newData);
      })
      .catch((err) => {
        console.log("InstrumentInOut data fetching error: ", err);
      });
  }

  function getFinalData() {
    const instrumentMasterIds = inputStates?.instrumentMasterId
      ?.split(",")
      ?.map((id) => `'${id.trim()}'`)
      ?.join(",");

    let SQLQuery = {
      query: `SELECT iio.*, im.identificationNumber, ins.name, vm.name AS vendorName, u.userName FROM instrumentInOut iio LEFT JOIN instrument_master im ON iio.instrumentMasterId = im.id LEFT JOIN instrument ins ON im.instrumentId = ins.id LEFT JOIN vendor_master vm ON iio.vendorId = vm.id LEFT JOIN users u ON im.assignedUser = u.id ${
        instrumentMasterIds?.length > 0
          ? `WHERE iio.instrumentMasterId IN (${instrumentMasterIds})`
          : `WHERE iio.vendorId IN (${inputStates.VendorId.join(", ")})`
      } ORDER BY iio.id DESC`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        let newData = res.data;
        setFinalData(newData);
      })
      .catch((err) => {
        console.log("InstrumentInOut data fetching error: ", err);
      });
  }

  function getVendorMasterData() {
    axiosWithToken
      .get(BASE_URL + `vendor_master`)
      .then((res) => {
        let newData = res.data;
        setVendorMasterData(newData);
      })
      .catch((err) => {
        console.log("instrument data fetching error: ", err);
      });
  }

  function processInOutData(finalData) {
    const uniqueCompanyNames = new Set();
    const instrumentCountMap = new Map();

    finalData?.forEach((item) => {
      // Add unique company names to the Set
      if (item.vendorName) {
        uniqueCompanyNames.add(item.vendorName.trim()); // Using trim to handle trailing spaces
      }

      // Count occurrences of each instrumentMasterId and store additional details
      if (item.name) {
        const instrumentId = item.name.trim();
        const instrumentDetails = {
          name: item.name || null,
          identificationNumber: item.identificationNumber || null,
          count: 1,
        };

        if (instrumentCountMap.has(instrumentId)) {
          const existingDetails = instrumentCountMap.get(instrumentId);
          existingDetails.count += 1;
          // Keep the same name and identificationNumber
          instrumentCountMap.set(instrumentId, existingDetails);
        } else {
          instrumentCountMap.set(instrumentId, instrumentDetails);
        }
      }
    });

    // Convert the Set to an array for unique company names
    const companyNamesArray = Array.from(uniqueCompanyNames);

    // Convert the Map to an array of objects for instrument counts
    const instrumentCountsArray = Array.from(
      instrumentCountMap,
      ([name, details]) => ({
        name,
        name: details.name,
        identificationNumber: details.identificationNumber,
        count: details.count,
      })
    );

    return {
      companyNamesArray,
      instrumentCountsArray,
    };
  }

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [10, 1, 20, 1],
          filename: `masterInOut.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("helvetica", "bold");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 10;
        const borderX = leftMargin;
        const borderY = 5;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 830);

        // const text = "SUMMARY REPORT";
        // const pageWidth = pdf.internal.pageSize.getWidth();
        // const textWidth =
        //   (pdf.getStringUnitWidth(text) * 12) / pdf.internal.scaleFactor;
        // const x = (pageWidth - textWidth) / 2;
        // pdf.setFontSize(12);
        // pdf.text(text, x, 20);
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `inOutReport.pdf`;
      a.click();

      setDownlaodPdfLoading(false);

      // refresh();
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };



  useEffect(() => {
    let result = processInOutData(finalData);
    setInstrumentCount(result);
  }, [finalData]);

  useEffect(() => {
    getVendorMasterData();
  }, []);

  useEffect(() => {
    getInstrumentData();
  }, [inputStates.VendorId]);

  return (
    <div style={{ padding: "0px 10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Autocomplete
            multiple
            size="small"
            id="combo-box-demo"
            options={vendorMasterData}
            getOptionLabel={(option) => option.name}
            value={
              vendorMasterData.filter((lab) =>
                inputStates?.VendorId?.includes(lab.id)
              ) || []
            }
            renderInput={(params) => (
              <TextField {...params} label="Vendor Name" />
            )}
            onChange={(event, value) => {
              if (value) {
                const selectedIds = value.map((v) => v.id);
                updateInputObject("VendorId", selectedIds);
              } else {
                updateInputObject("VendorId", []);
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            multiple
            options={instrumentList}
            getOptionLabel={(option) =>
              `${option.name}, ${option.identificationNumber}`
            }
            value={instrumentList.filter((instrument) =>
              inputStates.instrumentMasterId
                ? inputStates.instrumentMasterId
                    ?.split(",")
                    ?.includes(instrument.id.toString())
                : false
            )}
            renderInput={(params) => (
              <TextField {...params} label="InstrumentInOut Name *" />
            )}
            onChange={(event, value) => {
              const selectedIds = value
                .map((instrument) => instrument.id)
                .join(",");
              updateInputObject("instrumentMasterId", selectedIds);
            }}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={2} lg={2}>
          <Button
            variant="contained"
            size="small"
            sx={{ height: "40px" }}
            onClick={() => {
              getFinalData();
            }}
          >
            Prepare Data
          </Button>
        </Grid>

        <Grid item xs={6} sm={6} md={2} lg={2}>
          <ExcelDownload
            finalData={finalData}
            notNeededColumn={notNeededColumn}
          />
        </Grid>
        <Grid item xs={4} sm={3} md={3} lg={2} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            sx={{ height: "40px" }}
            onClick={() => {
              setIsPrint(true);
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>

      <div style={{ marginTop: "20px" }}>
        <div
          style={{ width: isPrint ? "95%" : "100%", margin: "auto" }}
          ref={printComponentRef}
        >
          {isPrint ? (
            <>
              <div style={{ padding: "5px 10px", marginBottom:"20px" }}>
                <div style={{ textAlign: "center" }}>
                  <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    INDEX
                  </h4>
                  <br />
                  {/* <h6>
              INSPECTION DETAILS OF TESTING OF HOIST, LIFT & LIFTING MACHINERY
            </h6> */}
                </div>
                <div
                  id="summaryBorder"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  <table style={{ borderCollapse: "collapse" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            fontSize: "15px",
                            border: "none",
                            width: "40%",
                          }}
                        >
                          Vendors Name{" "}
                        </td>
                        <td
                          style={{
                            fontSize: "15px",
                            border: "none",
                            width: "60%",
                            fontWeight: 100,
                          }}
                        >
                          : &nbsp;{" "}
                          {instrumentCount?.companyNamesArray?.join(", ")}
                        </td>
                      </tr>

                      {instrumentCount?.instrumentCountsArray?.map(
                        (instrument, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                fontSize: "15px",
                                border: "none",
                                width: "40%",
                              }}
                            >
                              {instrument.name}
                              {/* {"("}
                              {instrument.identificationNumber}
                              {")"} */}
                            </td>
                            <td
                              style={{
                                fontSize: "15px",
                                border: "none",
                                width: "60%",
                                fontWeight: 100,
                              }}
                            >
                              :&nbsp; &nbsp; {instrument.count}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : null}
          <Tables
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            // handleDelete={handleDelete}
            columns={columns}
            finalData={finalData}
            //   actions={actions}
            totalRows={finalData.length}
            //   printComponentRef={printComponentRef}
            isPrint={true}
          />
        </div>
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
    </div>
  );
};

export default InstrumentInOutReport;
