import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import MultiValueInputModal from "../../utils/components/multiValueInputModal";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const modalConfigs = [
  {
    buttonLabel: "Ranges",
    transformedStringLabel: "instrumentRange",
  },
  {
    buttonLabel: "Least Count",
    transformedStringLabel: "leastCount",
  },
  {
    buttonLabel: "Accuracy",
    transformedStringLabel: "accuracy",
  },
];

const calArray = [
  { id: 1, label: "3 Months" },
  { id: 2, label: "6 Months" },
  { id: 3, label: "12 Months" },
  { id: 3, label: "18 Months" },
  { id: 4, label: "24 Months" },
];

const EditinstrumentMaster = () => {
  const [inputStates, setInputStates] = React.useState({ instrumentId: null });
  const [labList, setLabList] = React.useState([]);
  const [instrumentList, setInstrumentList] = React.useState([]);
  const [plantData, setPlantData] = React.useState([]);
  const params = useParams();
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const userType = localStorage.getItem("type");


  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    if (params.id) {
      axiosWithToken
        .patch(url + `instrument_master/${params.id}`, inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Instrument edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axiosWithToken
        .post(url + "instrument_master", inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Instrument created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchInstrument_master = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instrument_master/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let newData = {
          ...inputStates,
          ...dataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  function getLABData() {
    axiosWithToken
      .get(BASE_URL + `lab_master`)
      .then((res) => {
        let newData = res.data;
        setLabList(newData);
      })
      .catch((err) => {
        console.log("lab_master data fetching error: ", err);
      });
  }

  function getInstrumentData() {
    axiosWithToken
      .get(BASE_URL + `instrument`)
      .then((res) => {
        let newData = res.data;
        setInstrumentList(newData);
      })
      .catch((err) => {
        console.log("Instrument data fetching error: ", err);
      });
  }

  function fetchPlantData() {
    axiosWithToken
      .get(BASE_URL + `plant_master`)
      .then((res) => {
        setPlantData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const validateData = () => {
    let isValid = true;

    if (!inputStates?.instrumentId) {
      toast.error("Please select a Instrument");
      isValid = false;
    }
    // if (!inputStates?.serialNumber) {
    //   toast.error("Please  Enter Serial Number");
    //   isValid = false;
    // }
    if (!inputStates?.identificationNumber) {
      toast.error("Please  Enter Identification Number");
      isValid = false;
    }
    // if (!inputStates?.modelNo) {
    //   toast.error("Please  Enter Model Number");
    //   isValid = false;
    // }
    if (!inputStates?.instrumentRange) {
      toast.error("Please  Add Instrument Range");
      isValid = false;
    }


    return isValid;
  };


  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchInstrument_master();
  }, [params.id]);

  useEffect(() => {
    getLABData();
    getInstrumentData();
    fetchPlantData();
  }, []);

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Create/Update Instrument Master
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={instrumentList}
                disabled={userType == 3 }
                getOptionLabel={(option) => option.name}
                value={
                  instrumentList.find(
                    (lab) => lab.id === inputStates.instrumentId
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Instrument Name *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("instrumentId", value.id);
                    // updateInputObject("name", value.name);
                  } else {
                    updateInputObject("instrumentId", "");
                    // updateInputObject("name", "");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Serial Number *"
                size="small"
                value={inputStates.serialNumber || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("serialNumber", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Make "
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                value={inputStates.make || ""}
                onChange={(e) => {
                  updateInputObject("make", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Identification Number *"
                size="small"
                value={inputStates.identificationNumber || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("identificationNumber", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Model Number *"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                // rows={3}
                // multiline
                // maxRows={5}
                value={inputStates.modelNo ? inputStates.modelNo : ""}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("modelNo", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Block Number"
                size="small"
                value={inputStates.blockNo || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("blockNo", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Attached Instrument Name"
                size="small"
                value={inputStates.attachedInstrumentName || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("attachedInstrumentName", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Floor Name"
                size="small"
                value={inputStates.floorName || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("floorName", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Location of Equipment's "
                size="small"
                value={inputStates.location || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("location", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                //   disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={calArray}
                getOptionLabel={(option) => option.label}
                value={
                  calArray.find(
                    (lab) => lab.label === inputStates.calibrationFrequency
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Calibration Frequency  *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("calibrationFrequency", value.label);
                  } else {
                    updateInputObject("calibrationFrequency", "");
                  }
                }}
              />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={labList}
                getOptionLabel={(option) => option.name}
                value={
                  labList.find((lab) => lab.id === inputStates.labId) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Lab Name *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("labId", value.id);
                  } else {
                    updateInputObject("labId", "");
                  }
                }}
              />
            </Grid> */}

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Test points "
                size="small"
                value={inputStates.testPoints || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("testPoints", e.target.value);
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Expanded Uncertainty *"
                size="small"
                value={inputStates.expandedUncertainty || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("expandedUncertainty", e.target.value);
                }}
              />
            </Grid> */}

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={plantData}
                getOptionLabel={(option) => option.location}
                value={
                  plantData.find((lab) => lab.id === inputStates.plantId) ||
                  null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Plant Location " />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("plantId", value.id);
                    // updateInputObject("name", value.name);
                  } else {
                    updateInputObject("plantId", "");
                    // updateInputObject("name", "");
                  }
                }}
              />
            </Grid>

            <Grid item spacing={3}>
              <div style={{ display: "flex", gap: "50px", flexWrap:"wrap" }}>
                {modalConfigs.map((config, index) => (
                  <MultiValueInputModal
                    key={index}
                    buttonLabel={config.buttonLabel}
                    transformedStringLabel={config.transformedStringLabel}
                    inputStates={inputStates}
                    updateInputObject={updateInputObject}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
              sx={{ m: 0 }}
              onClick={() => {
                if (!validateData()) return;
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </div>
    </div>
  );
};

export default EditinstrumentMaster;
