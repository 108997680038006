import { Autocomplete, Button, Grid, Paper, TextField, Toolbar, Typography } from '@mui/material'
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from 'react'
import { BASE_URL } from "../../global";
import axiosWithToken from '../../utils/components/axiosTokenConfig';


const EditLabMaster = () => {
  const [inputStates, setInputStates] = React.useState({});
  const params = useParams();

  const updateInputObject = (key, value, q) => {
      let newInputObject = {
        ...inputStates,
      };    
      newInputObject[key] = value;    
      setInputStates({ ...newInputObject });
    };

    const handleSubmit =()=>{
      console.log("inputeStates",inputStates)

      let url = BASE_URL;
      if (params.id) {
        axiosWithToken
          .patch(url + `lab_master/${params.id}`, inputStates)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("LAB edit successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
  
      } else {
        axiosWithToken
          .post(url + "lab_master", inputStates)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("LAB created successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
  
      }

    }

    const fetchLab_master = (inpt) => {
      let url = BASE_URL;
      axiosWithToken
        .get(url + `lab_master/${params.id}`)
        .then((res) => {
          let dataObject = res.data[0];
  
          let newData = {
            ...inputStates,
            ...dataObject,
           
          };
            dataObject && setInputStates(newData);
         
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    };

    var refresh = () => {
      window.location.reload(false);
    };

useEffect(()=>{
 if(params.id)fetchLab_master()
},[params.id])

return (
  <div>
          <div>
   <Paper sx={{ mt: 2, p: 2 }}>
   <Typography variant="h6" component="h6" style={{ float: "left" }}>
      Create/Update LAB Master
    </Typography>
    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
     
      <Grid item xs={3}>
        <TextField
          id="outlined-basic"
          label="LAB Name  *"
          size="small"
          fullWidth
          variant="outlined"
          value={inputStates.name || ""}
          onChange={(e) => {
            updateInputObject("name", e.target.value);
          }}
        />
      </Grid>
    </Grid>
    <Toolbar style={{ padding: "0px", overflow: "auto" }}>
    <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
               handleSubmit()             
          }}
        >
      {params.id ? "Update":"Save"}
        </Button>
    </Toolbar>
   </Paper>
  </div>
  </div>
)
}

export default EditLabMaster