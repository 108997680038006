import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Paper } from "@mui/material";
import { BASE_URL } from "../../global";
import Table from "react-bootstrap/Table";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

export default function InstrumentMaster() {
  const [todaysInstrumentCount, setTodaysInstrumentCount] = useState(0);
  const [todaysCalibrationRegisterCount, setTodaysCalibrationRegisterCount] = useState(0);

  const getTodayData = async () => {
    try {
      const instrumentResponse = await axiosWithToken.post(
        BASE_URL + `dynamic`,
        {
          query: `SELECT COUNT(*) as no_of_rows FROM instrument WHERE DATE(lastModified) = CURDATE();`,
        }
      );
      const calibrationRegisterResponse = await axiosWithToken.post(
        BASE_URL + `dynamic`,
        {
          query: `SELECT COUNT(*) as no_of_rows FROM calibration_register WHERE DATE(lastModified) = CURDATE();`,
        }
      );

      setTodaysInstrumentCount(instrumentResponse.data[0].no_of_rows);
      setTodaysCalibrationRegisterCount(
        calibrationRegisterResponse.data[0].no_of_rows
      );
    } catch (error) {
      console.error("Error fetching today's data:", error);
    }
  };

  useEffect(() => {
    getTodayData();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Instrument </th>
            <th>Calibration Register</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{todaysInstrumentCount}</td>
            <td>{todaysCalibrationRegisterCount}</td>
          </tr>
        </tbody>
      </Table>
    </Paper>
  );
}