import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ExcelDownload from "../../utils/components/excelDownload";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "urlNumber",
    label: "ULR Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },

  {
    id: "identificationNumber",
    label: "Identification Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentRange",
    label: "Ranges",
    align: "left",
    minWidth: 40,
  },
  {
    id: "leastCount",
    label: "Least Count",
    align: "left",
    minWidth: 40,
  },
  {
    id: "accuracy",
    label: "Accuracy",
    minWidth: 40,
    align: "left",
  },
  {
    id: "make",
    label: "Make",
    align: "left",
    minWidth: 40,
  },
  {
    id: "modelNo",
    label: "Model Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "calibrationDate",
    label: "Calibration Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "dueDate",
    label: "DUE Date",
    align: "left",
    minWidth: 40,
  },
];

const notNeededColumn = [
  "id",
  "status",
  "instrumentId",
  "VendorId",
];

const HistoryList = () => {
  const [page, setPage] = React.useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [inputStates, setInputStates] = React.useState({});
  const [instrumentList, setInstrumentList] = React.useState([]);

  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const printComponentRef = React.useRef();

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Instrument Name": "instrument.name",
        "Certificate Number": "calibration_register.certificateNumber",
        "ULR Number": "calibration_register.urlNumber",
      }[searchBy];
      whereConditions = `and ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` calibration_register.calibrationDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }else{
      whereConditions += ` WHERE calibration_register.dueDate IS NOT NULL AND DATE(calibration_register.dueDate) <= CURDATE()`
    }

    if (inputStates?.instrumentId) {
      whereConditions += ` and instrument_master.id = ${inputStates?.instrumentId}  `;
    }

    if (inputStates?.identificationNumber) {
      whereConditions += ` and instrument_master.identificationNumber = '${inputStates?.identificationNumber}'`;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let instrumentIds = inputStates?.instrumentIds?.join(', '); // Assuming inputStates.instrumentIds is an array
let identificationNumbers = inputStates?.identificationNumbers
  ?.map((num) => `'${num}'`)
  .join(', ');

  let data = { query: `SELECT cr.*, crh.*, im.make, im.modelNo, im.serialNumber, i.name AS instrumentName, REPLACE(REPLACE(REPLACE(im.instrumentRange, '#', ''), '||', ','), '|', ' to ') AS instrumentRange, REPLACE(REPLACE(REPLACE(im.leastCount, '#', ''), '||', ','), '|', ' to ') AS leastCount, REPLACE(REPLACE(REPLACE(im.accuracy, '#', ''), '||', ','), '|', ' to ') AS accuracy FROM calibration_register cr JOIN calibration_register_history crh ON cr.id = crh.calibrationId JOIN instrument_master im ON im.id IN (${inputStates?.instrumentIds?.join(', ')}) JOIN instrument i ON i.id = im.instrumentId WHERE cr.instrumentId IN (SELECT instrumentId FROM instrument_master WHERE id IN (${inputStates?.instrumentIds?.join(', ')})) AND cr.identificationNumber IN (${inputStates?.identificationNumbers?.map((num) => `'${num}'`).join(', ')}) ORDER BY crh.id DESC ${pagination_settings}` };


    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows  FROM calibration_register cr JOIN calibration_register_history crh ON cr.id = crh.calibrationId JOIN instrument_master im ON im.id IN (${inputStates?.instrumentIds?.join(', ')}) JOIN instrument i ON i.id = im.instrumentId WHERE cr.instrumentId IN (SELECT instrumentId FROM instrument_master WHERE id IN (${inputStates?.instrumentIds?.join(', ')})) AND cr.identificationNumber IN (${inputStates?.identificationNumbers?.map((num) => `'${num}'`).join(', ')}) ORDER BY crh.id DESC `;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const search = () => {
    fetchFinalData();
    getTotalRows();
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `vendor_master/${id}`)
      .then((res) => {
        toast("Vendor is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  // const updateInputObject = (key, value, q) => {
  //   let newInputObject = {
  //     ...inputStates,
  //   };
  //   newInputObject[key] = value;
  //   setInputStates({ ...newInputObject });
  // };


  const updateInputObject = (updates) => {
    setInputStates((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  function getInstrumentData() {
    let paylod = {
      query:
        "SELECT instrument_master.*, instrument.name FROM instrument_master LEFT JOIN  instrument ON  instrument_master.instrumentId = instrument.id",
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, paylod)
      .then((res) => {
        let newData = res.data;
        setInstrumentList(newData);
      })
      .catch((err) => {
        console.log("instrument data fetching error: ", err);
      });
  }


  var refresh = () => {
    window.location.reload(false);
  };


  useEffect(() => {
    getInstrumentData();
  }, []);

  useEffect(() => {
    if (inputStates.instrumentIds){
      fetchFinalData();
      getTotalRows();
    } 
  }, [rowsPerPage, page]);

  const actions = [
    // {
    //   id: "delete",
    //   tooltip: "Delete Vendor",
    //   icon: <DeleteIcon style={{ color: "#dc3545" }} />,
    //   handler: (row)  => window.confirm('Are you sure you want to delete this?') && handleDelete(row?.id),
    // },
  ];

  const generateLetterHeadFooter = async () => {
    let htmlString = `
      <div>
        <img src=${UnitechFooter} style="width:100%; height:150px;"/>
      </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateLetterHeadHeader = async () => {
    let htmlString = `
      <div>
        <img src=${UnitechHeader} style="width:100%; height:170px;"/>
      </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [80, 1, 135, 1],
        filename: `SummaryReport.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 790, 595, 50);
      }
    }

    pdf.save();
  };

  return (
    <div style={{padding:"0px 10px"}}>
      <Grid
        container
        spacing={2}
        justifyContent={"flex-start"}
        alignItems="center"
      >

<Grid item xs={12} sm={6} md={6} lg={6}>
  <Autocomplete
    multiple
    size="small"
    id="combo-box-demo"
    options={instrumentList}
    getOptionLabel={(option) => `${option.name}, ${option.identificationNumber}`}
    value={instrumentList.filter((lab) =>
      inputStates.instrumentIds?.includes(lab.id)
    )}
    renderInput={(params) => (
      <TextField {...params} label="Instrument Name *" />
    )}
    onChange={(event, value) => {
      if (value && value.length > 0) {
        const selectedIds = value.map((v) => v.id);
        const selectedIdentificationNumbers = value.map(
          (v) => v.identificationNumber
        );
        updateInputObject({
          instrumentIds: selectedIds,
          identificationNumbers: selectedIdentificationNumbers,
        });
      } else {
        updateInputObject({
          instrumentIds: [],
          identificationNumbers: [],
        });
      }
    }}
  />
</Grid>


        {/* <Grid item xs={12} sm={6} md={2} lg={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={dentificationNumbertData}
            getOptionLabel={(option) => option.identificationNumber}
            value={
              dentificationNumbertData.find(
                (lab) =>
                  lab.identificationNumber === inputStates.identificationNumber
              ) || null
            }
            renderInput={(params) => (
              <TextField {...params} label="Identification Number *" />
            )}
            onChange={(event, value) => {
              if (value) {
                updateInputObject(
                  "identificationNumber",
                  value.identificationNumber
                );
              } else {
                updateInputObject("identificationNumber", "");
              }
            }}
          />
        </Grid> */}

        <Grid item xs={6} sm={6} md={0.7} lg={0.7} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            sx={{ height :"40px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} textAlign={"left"}
        >
        <ExcelDownload
        finalData={data}
        notNeededColumn={notNeededColumn}
      />
        </Grid>
      </Grid>
     
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <div ref={printComponentRef} style={{ width: "100%", margin: "auto" }}>
          <Tables
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleDelete={handleDelete}
            columns={columns}
            finalData={data}
            actions={actions}
            totalRows={totalRows}
          />
          <div style={{ width: "100%" }} id="header-section"></div>
        </div>
      </TableContainer>
    </div>
  );
};

export default HistoryList;
