import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import { Button, TableContainer, Toolbar, TextField, Autocomplete, Grid, useMediaQuery } from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 20 },
  { id: "userName", label: "User Name", minWidth: 40 },
  { id: "password", label: "Password", minWidth: 40 },
  { id: "email", label: "Email", minWidth: 40 },
  { id: "contactNo", label: "Contact Number", minWidth: 40 },

  { id: "type_label", label: "Type", minWidth: 40 },
];

const UserList = () => {
  const [page, setPage] = React.useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "User Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [searched, setSearched] = React.useState(false);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "User Name": "userName",
        // "Address": "address",
        // "Certificate Number": "certificateNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }


    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT *, CASE WHEN type = 1 THEN 'Admin' WHEN type = 2 THEN 'Plant Head' WHEN type = 3 THEN 'Vendor' WHEN type = 4 THEN 'Department Head' WHEN type = 5 THEN 'Supervisor' WHEN type = 6 THEN 'Co-Ordinator' ELSE 'Unknown' END AS type_label FROM users ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM users  ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
 
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data)
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);    
    setSearched(true);
    fetchFinalData();
    getTotalRows();
  };

  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched");
    sessionStorage.removeItem("currentPath");
    setSearchBy("User Name");
    setSearchKey("");
    setSearched(false); 
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `users/${id}`)
      .then((res) => {
        toast("User is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (searched==false) {
      fetchFinalData();
      getTotalRows();
    }
  }, [searched]);
  
  useEffect(() => {
    getTotalRows();
    fetchFinalData();
  }, [rowsPerPage,page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit User",
      link: true,
      linkTo: (id) => `/editUser/${id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete User",
      disabled:!(editAccess?.includes(2) || editAccess?.includes(0)) ? true : false,
      icon: <DeleteIcon   style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' :  "#dc3545" }}  />,
      handler: (row)  => window.confirm('Are you sure you want to delete this?') && handleDelete(row?.id),

    },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 1, px:1  }}>
        <Toolbar style={{padding:0}}>
          {showComponent("add") && (
            <Button
              variant="contained"
              size="small"
              component={Link}
              to="/editUser"
              // onClick={() => {
              //   setTitle("Master/ add new user");
              // }}
            >
              <b>ADD NEW</b>
            </Button>
          )}
        </Toolbar>
        <Grid
        container
        spacing={2}
        justifyContent={"flex-start"} 
        alignItems="center"
        style={{ padding: "5px 5px" }}
      >
        <Grid item xs={6} sm={6} md={2} lg={2}
        >
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          id="combo-box-demo"
          value={searchBy}
          options={
            _userType != 3
              ? [
                  { key: "userName", label: "User Name" },
                  // { key: "address", label: "Address" },
                  // { key: "certificateNumber", label: "Certificate Number" },
                ]
              : [{ key: "name", label: "LAB Name" }]
          }
         
          renderInput={(params) => (
            <TextField {...params} label="Search By" />
          )}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}
        >
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          inputProps={{
            autoComplete: 'off',
          }}
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
            setSearched(false);
          }}
        />
        </Grid>
        <Grid item xs={6} sm={6} md={1} lg={1} textAlign={"left"}
        >
        <Button
          variant="contained"
          size="small"
          sx={{height:"40px"}}
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
        </Grid>
      </Grid>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  )
}

export default UserList