import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from "@mui/icons-material/History";
import ExcelDownload from "../../utils/components/excelDownload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";


var columns = [
    {
      id: "id",
      label: "Sr. No.",
      align: "left",
      minWidth: 40,
    },
    {
      id: "instrumentName",
      label: "Instrument Name",
      align: "left",
      minWidth: 40,
    },
    ,
    {
      id: "identificationNumber",
      label: "Identification Number",
      align: "left",
      minWidth: 40,
    },
    {
      id: "serialNumber",
      label: "Serial Number",
      align: "left",
      minWidth: 40,
    },
    {
      id: "instrumentRange",
      label: "Range",
      align: "left",
      minWidth: 40,
    },
    {
      id: "leastCount",
      label: "Least Count",
      align: "left",
      minWidth: 40,
    },
    {
      id: "accuracy",
      label: "Accuracy",
      minWidth: 40,
      align: "left",
    },
    {
      id: "make",
      label: "Make",
      minWidth: 40,
      align: "left",
    },
    {
      id: "modelNo",
      label: "Model",
      minWidth: 40,
      align: "left",
      align: "left",
    },
  ];

const AllocatedReport = () => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [vendorData, setVendorData] = React.useState([]);
  const [vendorID, setVendorID] = React.useState(0);
  const [FinalData, setFinalData] = React.useState([]);

  const fetchVendor_master = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `vendor_master?_fields=id,name`)
      .then((res) => {
        let dataObject = res.data;
        setVendorData(dataObject);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchData = () => {
    let SQLQuery = {
      query: `SELECT im.instrumentId,im.id, im.*,REPLACE(REPLACE(REPLACE(im.accuracy, '#', ''), '||', ','), '|', ' to ') AS accuracy, REPLACE(REPLACE(REPLACE(im.leastCount, '#', ''), '||', ','), '|', ' to ') AS leastCount, REPLACE(REPLACE(REPLACE(im.instrumentRange, '#', ''), '||', ','), '|', ' to ') AS instrumentRange, i.name AS instrumentName FROM instrument_master im LEFT JOIN instrument i ON im.instrumentId = i.id WHERE im.assignedUser =${vendorID} `,
    };

    let url = BASE_URL;
    axiosWithToken
      .post(url + `dynamic`, SQLQuery)
      .then((res) => {
        let dataObject = res.data;
        setFinalData(dataObject);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    fetchVendor_master();
  }, []);



  return (
    <div style={{padding:"0px 10px"}}>
      <Grid container  justifyContent={"flex-start"}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Autocomplete
            size="small"
            options={vendorData}
            value={
              vendorData?.filter((ele) => ele?.id == vendorID)?.[0] || null
            }
            getOptionLabel={(option) => `${option.id}, ${option.name}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a vendor"
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              setVendorID(value?.id);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={1} lg={1}>
          <Button
            variant="contained"
            size="small"
            sx={{ height :"40px" }}
            onClick={() => {
              fetchData();
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      <br />
        <Tables
         page={page}
         setPage={setPage}
         rowsPerPage={rowsPerPage}
         setRowsPerPage={setRowsPerPage}
          columns={columns}
          finalData={FinalData}
          totalRows={FinalData.length}
        />
    </div>
  );
};

export default AllocatedReport;
