import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListItemButton from "@mui/material/ListItemButton";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ConstructionIcon from "@mui/icons-material/Construction";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import InfoIcon from "@mui/icons-material/Info";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LoopIcon from "@mui/icons-material/Loop";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import FeedIcon from "@mui/icons-material/Feed";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TocIcon from "@mui/icons-material/Toc";
import GroupIcon from "@mui/icons-material/Group";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import BuildIcon from "@mui/icons-material/Build";
import Logo from "../image/logo.png";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TranslateIcon from '@mui/icons-material/Translate';


import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import {
  Box,
  Collapse,
  Divider,
  Grid,
  Modal,
  Popover,
  SwipeableDrawer,
  Switch,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Badge } from "react-bootstrap";
import { BASE_URL, DISABLED_MODULE } from "./../../global";
import { NotificationList } from "../notification/Notification";
import ResetPassword from "../resetPassword/resetPassword";
import SymbolCopyModal from "../../utils/components/symbolCopyModal";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    "& .MuiToolbar-root": {
      color: "grey",
      "&:hover": {
        color: "black",
      },
    },
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "#fff",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fff",
  },
  listItem: {
    color: "#637381",
    "&:hover": {
      backgroundColor: "#fff",
      // color: "white",
    },
  },
  selectedListItem: {
    backgroundColor: "white",
    color: "grey",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [resposiveAnchor, setResposiveAnchor] = React.useState({
    left: false,
  });
  const [dropDown, setDropDown] = useState(null);
  const [masterNested, setMasterNested] = React.useState(false);
  const [srfNested, setSrfNested] = React.useState(false);
  const [paymentNested, setPaymentNested] = React.useState(false);
  const [intermediateProcedure, setIntermediateProcedure] =
    React.useState(false);
  const classes = useStyles();
  const userName = localStorage.getItem("userName");
  const userType = localStorage.getItem("type");
  const modules = localStorage.getItem("modules")?.split(",");
  const [isModalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationsNo, setNotificationsNo] = React.useState(0);
  const [InstrumentInOut, setInstrumentInOut] = React.useState(false);
  const [Reports, setReports] = React.useState(false);
  const [isSymbolModalOpen, setIsSymbolModalOpen] = useState(false);
  const openSymbolModal = () => {
    setIsSymbolModalOpen(true);
  };
  const closeSymbolModal = () => {
    setIsSymbolModalOpen(false);
  };
  const location = useLocation();
  const pathname = location.pathname;
  const routeSegments = pathname.split("/");
  let selectedRoute = routeSegments[1];
  if (routeSegments[1] === "master" && routeSegments.length > 1) {
    selectedRoute = routeSegments[2];
  }
  const convertToTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const selectedRouteInUpperCase = convertToTitleCase(selectedRoute);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    sessionStorage.setItem("SideBar", true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    sessionStorage.setItem("SideBar", false);
  };
  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const logout = () => {
    props.setIsLoggedIn(false);
    window.localStorage.clear();
    window.sessionStorage.clear();
    navigate("/");
    window.location.reload(false);
  };

  const handleClickDropDown = (event) => {
    setDropDown(event.currentTarget);
  };

  const onIdle = () => {
    logout();
  };

  const onActive = () => {};

  const onAction = () => {};

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 15 * 60 * 1000,
    throttle: 250,
  });

  // -----------------------------------------------

  const [open1, setOpen1] = React.useState(false);
  const anchorRef = React.useRef(null);
  const storage_userName = localStorage.getItem("userName");
  const storage_userType = localStorage.getItem("type");
  const storage_modules = localStorage
    .getItem("modules")
    ?.split(",")
    .map((e) => e)
    .map((e) => Number(e));

  const hasAccessToModule = (key) => {
    key = Number(key);
    if (DISABLED_MODULE?.includes(key)) return false;
    if (`${storage_userType}` === "1") return true;
    else if (
      `${storage_userType}` == "3" ||
      `${storage_userType}` == "4" ||
      `${storage_userType}` == "2" ||
      `${storage_userType}` == "5"
    ) {
      if (storage_modules?.includes(key)) return true;
      else return false;
    } else {
      if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(key))
        return true;
      else return false;
    }
  };

  const handleCloseDropDown = () => {
    setDropDown(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setResposiveAnchor({ ...resposiveAnchor, [anchor]: open });
  };
  
  let previouspath = sessionStorage.getItem("currentPath");
  const clearSearchFilterSessionStorage = (to) => {
    if (to !== previouspath) {
      sessionStorage.removeItem("searchBy");
      sessionStorage.removeItem("searchKey");
      sessionStorage.removeItem("searched");
      sessionStorage.removeItem("currentPath");
    }
    navigate(to);
  };

  const sidebarListItems = () => (
    <List
      className={classes.drawer}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton
        className={classes.listItem}
         onClick={()=>clearSearchFilterSessionStorage("/dashboard")}
      >
        <ListItemIcon className={classes.listItem}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText
          primary={<span style={{ fontWeight: "bold" }}>Dashboard</span>}
        />
      </ListItemButton>
      {hasAccessToModule(2) && !DISABLED_MODULE?.includes("Instrument") && (
        <ListItemButton
          className={classes.listItem}
          onClick={()=>clearSearchFilterSessionStorage("/instrumentList")}
        >
          <ListItemIcon className={classes.listItem}>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText
            primary={<span style={{ fontWeight: "600" }}>Instrument</span>}
          />
        </ListItemButton>
      )}
      {hasAccessToModule(3) &&
        !DISABLED_MODULE?.includes("Instrument Master") && (
          <ListItemButton
            className={classes.listItem}
            onClick={()=>clearSearchFilterSessionStorage("/instrumentMasterList")}
          >
            <ListItemIcon className={classes.listItem}>
              <ConstructionIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <span style={{ fontWeight: "600" }}>Instrument Master</span>
              }
            />
          </ListItemButton>
        )}

      {hasAccessToModule(4) && !DISABLED_MODULE?.includes("Plant Master") && (
        <ListItemButton
          className={classes.listItem}
          onClick={()=>clearSearchFilterSessionStorage("/plantMasterList")}
        >
          <ListItemIcon className={classes.listItem}>
            <MapsHomeWorkIcon />
          </ListItemIcon>
          <ListItemText
            primary={<span style={{ fontWeight: "600" }}>Plant </span>}
          />
        </ListItemButton>
      )}

      {hasAccessToModule(6) && !DISABLED_MODULE?.includes("Vendor Master") && (
        <ListItemButton
          className={classes.listItem}
          onClick={()=>clearSearchFilterSessionStorage("/vendorMasterList")}
        >
          <ListItemIcon className={classes.listItem}>
            <Diversity3Icon />
          </ListItemIcon>
          <ListItemText
            primary={<span style={{ fontWeight: "600" }}>Vendor </span>}
          />
        </ListItemButton>
      )}

      {hasAccessToModule(7) &&
        !DISABLED_MODULE?.includes("Calibration Register") && (
          <ListItemButton
            className={classes.listItem}
            onClick={()=>clearSearchFilterSessionStorage("/calibrationRegisterList")}
          >
            <ListItemIcon className={classes.listItem}>
              <FeedIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <span style={{ fontWeight: "600" }}>Calibration Register</span>
              }
            />
          </ListItemButton>
        )}

      {hasAccessToModule(8) &&
        !DISABLED_MODULE?.includes("Instrument Status") && (
          <ListItemButton
            className={classes.listItem}
            onClick={()=>clearSearchFilterSessionStorage("/instrumentStatusList")}
          >
            <ListItemIcon className={classes.listItem}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <span style={{ fontWeight: "600" }}>Instrument Status </span>
              }
            />
          </ListItemButton>
        )}

      {/* {hasAccessToModule(9) && !DISABLED_MODULE?.includes("Scheduler") && (
        <ListItemButton
          className={classes.listItem}
             onClick={()=>clearSearchFilterSessionStorage("/schedulerList")}
        >
          <ListItemIcon className={classes.listItem}>
            <TocIcon />
          </ListItemIcon>
          <ListItemText
            primary={<span style={{ fontWeight: "600" }}>Scheduler</span>}
          />
        </ListItemButton>
      )} */}

      {/* {hasAccessToModule(10) && !DISABLED_MODULE?.includes("History") && (
        <ListItemButton
          className={classes.listItem}
             onClick={()=>clearSearchFilterSessionStorage("/historyList")}
        >
          <ListItemIcon className={classes.listItem}>
            <ManageHistoryIcon />
          </ListItemIcon>
          <ListItemText
            primary={<span style={{ fontWeight: "600" }}>History </span>}
          />
        </ListItemButton>
      )} */}

      {hasAccessToModule("16") &&
        !DISABLED_MODULE?.includes("Instrument In/Out") && (
          <ListItemButton
            className={classes.listItem}
            // component={Link}
            // to="/standardInOutList"
            onClick={(e) => setInstrumentInOut(!InstrumentInOut)}
          >
            <ListItemIcon className={classes.listItem}>
              <Tooltip title="Payment" arrow placement="right">
                <TocIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText
                primary={
                  <span style={{ fontWeight: "600" }}>Instrument In/Out</span>
                }
              />
            ) : (
              <ListItemText>
                <Typography
                  variant="p"
                  component="div"
                  align="left"
                  style={{ fontSize: "14px" }}
                >
                  {"Instrument In/Out"}
                </Typography>
              </ListItemText>
            )}
            {InstrumentInOut ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
      <Collapse in={InstrumentInOut} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.listItem}
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/instrumentInOutList")}
          >
            <ListItemIcon className={classes.listItem}>
              <Tooltip title="In/Out List" arrow placement="right">
                <LoopIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText
                primary={<span style={{ fontWeight: "600" }}>In/Out List</span>}
              />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"In/Out List"}
              </Typography>
            )}
          </ListItemButton>
          <ListItemButton
            className={classes.listItem}
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/instrumentInOutReport")}
          >
            <ListItemIcon className={classes.listItem}>
              <Tooltip title="In/Out Report" arrow placement="right">
                <PostAddIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText
                primary={
                  <span style={{ fontWeight: "600" }}>In/Out Report</span>
                }
              />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"In/Out Report"}
              </Typography>
            )}
          </ListItemButton>
        </List>
      </Collapse>

      {/* {hasAccessToModule(14) && !DISABLED_MODULE?.includes("Allocated Report") && (
        <ListItemButton
          className={classes.listItem}
          component={Link}
          to="/allocatedReport"
          onClick={() => {}}
        >
          <ListItemIcon className={classes.listItem}>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText
            primary={<span style={{ fontWeight: "600" }}>Allocated Report </span>}
          />
        </ListItemButton>
      )} */}

      {hasAccessToModule("17") && !DISABLED_MODULE?.includes("Reports") && (
        <ListItemButton
          className={classes.listItem}
          // component={Link}
          // to="/standardInOutList"
          onClick={(e) => setReports(!Reports)}
        >
          <ListItemIcon className={classes.listItem}>
            <Tooltip title="Payment" arrow placement="right">
              <PostAddIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText
              primary={<span style={{ fontWeight: "600" }}>Reports</span>}
            />
          ) : (
            <ListItemText>
              <Typography
                variant="p"
                component="div"
                align="left"
                style={{ fontSize: "14px" }}
              >
                {"Reports"}
              </Typography>
            </ListItemText>
          )}
          {Reports ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={Reports} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.listItem}
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/schedulerList")}
          >
            <ListItemIcon className={classes.listItem}>
              <Tooltip title="Scheduler" arrow placement="right">
                <TocIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText
                primary={<span style={{ fontWeight: "600" }}>Scheduler</span>}
              />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Scheduler"}
              </Typography>
            )}
          </ListItemButton>
          <ListItemButton
            className={classes.listItem}
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/historyList")}
          >
            <ListItemIcon className={classes.listItem}>
              <Tooltip title="History" arrow placement="right">
                <ManageHistoryIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText
                primary={<span style={{ fontWeight: "600" }}>History</span>}
              />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"History"}
              </Typography>
            )}
          </ListItemButton>

          <ListItemButton
            className={classes.listItem}
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/allocatedReport")}
          >
            <ListItemIcon className={classes.listItem}>
              <Tooltip title="Allocated Report" arrow placement="right">
                <PostAddIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText
                primary={
                  <span style={{ fontWeight: "600" }}>Allocated Report</span>
                }
              />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Allocated Report"}
              </Typography>
            )}
          </ListItemButton>
        </List>
      </Collapse>

      {hasAccessToModule(12) && !DISABLED_MODULE?.includes("Units") && (
        <ListItemButton
          className={classes.listItem}
          onClick={()=>clearSearchFilterSessionStorage("/unitsList")}
        >
          <ListItemIcon className={classes.listItem}>
            <AcUnitIcon />
          </ListItemIcon>
          <ListItemText
            primary={<span style={{ fontWeight: "600" }}>Units</span>}
          />
        </ListItemButton>
      )}

      {hasAccessToModule(13) && !DISABLED_MODULE?.includes("Users") && (
        <ListItemButton
          className={classes.listItem}
          onClick={()=>clearSearchFilterSessionStorage("/usersList")}
        >
          <ListItemIcon className={classes.listItem}>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText
            primary={<span style={{ fontWeight: "600" }}>Users</span>}
          />
        </ListItemButton>
      )}
      {isMobile && (
        <>
          <Divider
            sx={{
              backgroundColor: "black",
              height: "2px",
            }}
          />
          <ListItemButton>
            <ListItemIcon>
              <Tooltip title="Notification" arrow placement="right">
                <IconButton
                  // onClick={handleClick2}
                  size="small"
                  sx={{ height: "27px", width: "27px" }}
                  aria-controls={open2 ? "Role" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  style={{ backgroundColor: "white", marginRight: "5px" }}
                  disabled
                >
                  <AccountCircleIcon style={{ color: "#1976d2" }} />
                </IconButton>
              </Tooltip>
            </ListItemIcon>
            <Tooltip
              title="User (User Type)"
              style={{ textTransform: "uppercase" }}
            >
              <Typography
                variant="p"
                component="div"
                align="left"
                sx={{ fontSize: "90%" }}
              >
                {userName}
                <span style={{ textTransform: "lowercase" }}>
                  ({["admin", "Engineer", "client"][userType - 1]})
                </span>
              </Typography>
            </Tooltip>
          </ListItemButton>
          <ResetPassword isMobile={isMobile} />
          <ListItemButton>
            <ListItemIcon>
              <Tooltip title="Logout" arrow placement="right">
                <ExitToAppIcon />
              </Tooltip>
            </ListItemIcon>
            <Typography
              variant="p"
              component="div"
              align="left"
              onClick={logout}
            >
              {"Logout"}
            </Typography>
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <Tooltip title="info" arrow placement="right">
                <InfoIcon />
              </Tooltip>
            </ListItemIcon>
            <Typography variant="p" component="div" align="left">
              {"SCHEDULER Software Version 2.0.0"}
            </Typography>
          </ListItemButton>
        </>
      )}
    </List>
  );

  const MobileList = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DrawerHeader>
        <Typography variant="h6" gutterBottom component="div">
          Calibration
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      {sidebarListItems()}
    </Box>
  );

  const handleDrawerToggle = () => {
    if (isMobile) {
      setResposiveAnchor({ ...resposiveAnchor, left: !resposiveAnchor.left });
    } else {
      // handleDrawerOpen();
      setOpen(!open);
    }
  };

  React.useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar variant="dense" style={{ paddingRight: "5px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: "5px",
              ...(!isMobile && open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            align="left"
          >
            {selectedRouteInUpperCase}
          </Typography>
       
          <Tooltip title="Notification">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, mr: 1, height: "27px", width: "27px" }}
              aria-controls={open2 ? "available-roles" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              style={{ backgroundColor: "white" }}
            >
              <Badge
                badgeStyle={{
                  top: 12,
                  right: 12,
                  backgroundColor: "white",
                  border: "2px solid black",
                  color: "white",
                  fontWright: 900,
                }}
                color="secondary"
                badgeContent={`${notificationsNo}`}
                secondary={true}
              >
                <NotificationsNoneIcon style={{ color: "#1976d2" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip style={{ textTransform: "uppercase",display: "flex", gap: "0px", alignItems: "center", }} title="Symbol">
                <IconButton
                  size="small"
                  sx={{ ml: 2, mr: 2, height: "27px", width: "27px" }}
                  style={{ backgroundColor: "white" }}
                  onClick={openSymbolModal}
                >
                <TranslateIcon style={{ color:  "#1976d2", fontSize:"1.2rem" }}/>
                </IconButton>
                </Tooltip>
          <NotificationList
            anchorEl={anchorEl}
            open={open2}
            close={handleClose}
            notifyNumber={(n) => setNotificationsNo(n)}
            isMobile={isMobile}
          />
          {!isMobile && (
            <Typography
              variant="p"
              component="div"
              align="left"
              sx={{ fontSize: "90%", cursor: "pointer" }}
              onClick={handleClickDropDown}
            >
              <AccountCircleIcon />
              &nbsp;
              {userName} &nbsp;
              <span style={{ textTransform: "lowercase" }}>
                ({["admin", "Engineer", "client"][userType - 1]})
              </span>
            </Typography>
          )}
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "100%",
            }}
          >
            <div
              style={{
                height: "24px",
                backgroundColor: "#fff",
                position: "fixed",
                top: "7%",
                right: "0px",
                width: "auto",
                textAlign: "right",
                boxSizing: "border-box",
                color: "#fff",
              }}
            >
              <span
                style={{
                  backgroundColor: "#000",
                  padding: "3px",
                }}
              >
                SCHEDULER Software version 2.0.0
              </span>
            </div>
          </Modal>
        </Toolbar>
      </AppBar>

      <Popover
        open={Boolean(dropDown)}
        anchorEl={dropDown}
        onClose={handleCloseDropDown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: "10px" }}>
          <Grid container direction="column" spacing={2}>
            {!isMobile && (
              <Grid item>
                <ResetPassword isMobile={isMobile} />
              </Grid>
            )}
            {!isMobile && (
              <Grid item>
                <Button onClick={logout}>
                  <LogoutIcon style={{ marginRight: "10px" }} />
                  Logout
                </Button>
              </Grid>
            )}
            {!isMobile && (
              <Grid item>
                <Button onClick={handleButtonClick}>
                  <InfoIcon style={{ marginRight: "10px" }} />
                  Info
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Popover>

      {!isMobile ? (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader className={classes.drawer}>
            <img
              src={`${Logo}`}
              alt="logo"
              style={{
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                height: "80px",
                width: "50%",
                margin: "auto",
                textAlign: "center",
              }}
            />
            <IconButton
              sx={{ color: "#637381", fontWeight: "bold" }}
              onClick={handleDrawerClose}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Box
            sx={{
              height: "90vh",
            }}
            className={classes.drawer}
          >
            {sidebarListItems()}
          </Box>
        </Drawer>
      ) : null}

      <SwipeableDrawer
        anchor={"left"}
        open={resposiveAnchor["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {MobileList("left")}
      </SwipeableDrawer>

      {isSymbolModalOpen && <SymbolCopyModal onClose={closeSymbolModal} />}
    </>
  );
}
