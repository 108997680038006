import {
  Button,
  ButtonGroup,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { ToastContainer, toast } from "react-toastify";
import { showComponent } from "../../components/helper/helpers";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../global";
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import axiosWithToken from "./axiosTokenConfig";

const StatusArray = [
  { id: 1, name: "Missing" },
  { id: 2, name: "Scrapped" },
];

// style={{
//   color: instrumentStatusData.some(
//     (item) => item.id == row.id
//   )
//     ? "red"
//     : "inherit",
// }}

const Tables = (props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  let isSidebar = sessionStorage.getItem("SideBar");
  const [instrumentStatusData, setInstrument_status] = React.useState([]);

  // Function to handle individual row selection
  const handleRowSelect = (event, row) => {
    const selectedIndex = props?.selectedRows.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props?.selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props?.selectedRows.slice(1));
    } else if (selectedIndex === props?.selectedRows.length - 1) {
      newSelected = newSelected.concat(props?.selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props?.selectedRows.slice(0, selectedIndex),
        props?.selectedRows.slice(selectedIndex + 1)
      );
    }

    props?.setSelectedRows(newSelected);
  };

  const fetchInstrument_status = () => {
    let SQLQuery = {
      query:
        "SELECT im.* FROM instrument_master im INNER JOIN instrument_status ist ON im.instrumentId = ist.instrumentId AND im.identificationNumber = ist.identificationNumber",
    };
    let url = BASE_URL;
    axiosWithToken
      .post(url + `dynamic`, SQLQuery)
      .then((res) => {
        let dataObject = res.data;
        setInstrument_status(dataObject);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  // Function to handle "Select All" checkbox click
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.finalData.map((row) => row.id);
      props?.setSelectedRows(newSelecteds);
      return;
    }
    props?.setSelectedRows([]);
  };

  // Function to check if a row is selected
  const isSelected = (id) => props?.selectedRows?.indexOf(id) !== -1;

  const handleChangePage = (event, newPage) => {
    props?.setPage(newPage);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleChangeRowsPerPage = (event) => {
    props?.setRowsPerPage(+event.target.value);
    props?.setPage(0);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  useEffect(() => {
    fetchInstrument_status();
  }, []);

  return (
    <div>
      <TableContainer sx={{ maxHeight: isMobile ? "75vh" : "65vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
          width="100%"
        >
          <TableHead>
            <TableRow>
              {props?.multipleSelector && (
                <TableCell>
                  <Checkbox
                    indeterminate={
                      props?.selectedRows.length > 0 &&
                      props?.selectedRows.length < props.finalData.length
                    }
                    checked={
                      props.finalData.length > 0 &&
                      props?.selectedRows.length === props.finalData.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}

              {props?.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              {props?.actions && props.actions.length > 0 && (
                <TableCell>
                  <b>Actions</b>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.finalData &&
              props?.finalData?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={props?.multipleSelector ? isItemSelected : false}
                  >
                    {props?.multipleSelector && (
                      <TableCell>
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleRowSelect(event, row.id)}
                        />
                      </TableCell>
                    )}
                    {props?.columns?.map((column) => {
                      // let value = row[column.id];
                      let value;
                      if (
                        column.id == "date" ||
                        column.id == "calibrationDate" ||
                        column.id == "lastModified" ||
                        column.id == "outwordDate" ||
                        column.id == "actualReturnDate" ||
                        column.id == "dueDate" ||
                        column.id == "expectedReturnDate"
                      ) {
                        let newDate = moment(row[column.id]).format(
                          "DD-MM-YYYY"
                        );
                        value = row[column.id] ? newDate : null;
                      } else if (column.id == "status") {
                        let StatusArrayObject = StatusArray.filter(
                          (e) => e.id === row[column.id]
                        );
                        value = row[column.id]
                          ? StatusArrayObject[0]?.name
                          : null;
                      } else {
                        value = row[column.id];
                      }
                      if (column.id == "id") {
                        value = props?.page * props?.rowsPerPage + (index + 1);
                      }

                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            color:
                              props?.isInstrumentMaster &&
                              (column.id === "instrumentName" ||
                                column.id === "identificationNumber") &&
                              instrumentStatusData.some(
                                (item) => item.id == row["id"]
                              )
                                ? "red"
                                : props?.isCalibrationRegister &&
                                  column.id === "identificationNo" &&
                                  row["dueDate"] &&
                                  moment(row["dueDate"], "YYYY-MM-DD").isBefore(
                                    moment(),
                                    "day"
                                  )
                                ? "red"
                                : "inherit",
                          }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    {props?.actions && props.actions.length > 0 && (
                      <TableCell>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                          className="action-container"
                        >
                          {props?.actions.map((action) => {
                            // console.log("action.disabled",action.disabled)
                            return (
                              showComponent(action.id) && (
                                <Tooltip
                                  key={action.id}
                                  title={action.tooltip}
                                  placement="top-start"
                                >
                                  {action.link ? (
                                    <Button
                                      component={Link}
                                      to={action.linkTo(row.id)}
                                      disabled={action.disabled}
                                    >
                                      {action.icon}
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => action.handler(row)}
                                      disabled={action.disabled}
                                    >
                                      {action.icon}
                                    </Button>
                                  )}
                                </Tooltip>
                              )
                            );
                          })}
                        </ButtonGroup>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {!props?.isPrint && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={props?.totalRows}
          rowsPerPage={props?.rowsPerPage}
          page={props?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Tables;
