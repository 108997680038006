import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Grid,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "urlNumber",
    label: "ULR Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },

  {
    id: "identificationNumber",
    label: "Identification Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentRange",
    label: "Ranges",
    align: "left",
    minWidth: 40,
  },
  {
    id: "leastCount",
    label: "Least Count",
    align: "left",
    minWidth: 40,
  },
  {
    id: "accuracy",
    label: "Accuracy",
    minWidth: 40,
    align: "left",
  },
  {
    id: "make",
    label: "Make",
    align: "left",
    minWidth: 40,
  },
  {
    id: "modelNo",
    label: "Model Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "calibrationDate",
    label: "Calibration Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "dueDate",
    label: "DUE Date",
    align: "left",
    minWidth: 40,
  },
];

const SummaryList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [instrumentList, setInstrumentList] = React.useState([]);
  const [instrumentID, setInstrumentID] = React.useState(null);

  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const printComponentRef = React.useRef();

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Instrument Name": "instrument.name",
        "Certificate Number": "calibration_register.certificateNumber",
        "ULR Number": "calibration_register.urlNumber",
      }[searchBy];
      whereConditions = `and ${colName} like '%${searchKey}%'`;
    }
    if (instrumentID !== "" && instrumentID != null) {
      whereConditions = `and instrument.id = ${instrumentID}`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` cert.calibrationDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT calibration_register.*, instrument.name AS instrumentName,instrument_master.make,instrument_master.modelNo,instrument_master.serialNumber, instrument_master.identificationNumber as identificationNo, REPLACE(REPLACE(REPLACE(instrument_master.instrumentRange, '#', ''), '||', ','), '|', ' to ') AS instrumentRange, REPLACE(REPLACE(REPLACE(instrument_master.leastCount, '#', ''), '||', ','), '|', ' to ') AS leastCount, REPLACE(REPLACE(REPLACE(instrument_master.accuracy, '#', ''), '||', ','), '|', ' to ') AS accuracy FROM calibration_register LEFT JOIN instrument_master ON calibration_register.instrumentId = instrument_master.instrumentId LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id WHERE calibration_register.dueDate IS NOT NULL AND DATE(calibration_register.dueDate) <= CURDATE() ${whereConditions} ORDER BY calibration_register.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM calibration_register LEFT JOIN instrument_master ON calibration_register.instrumentId = instrument_master.instrumentId LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id WHERE calibration_register.dueDate IS NOT NULL AND DATE(calibration_register.dueDate) <= CURDATE() ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const search = () => {
    fetchFinalData();
    getTotalRows();
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `vendor_master/${id}`)
      .then((res) => {
        toast("Vendor is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  function getInstrumentData() {
    let paylod = {
      query:
        "SELECT instrument.* FROM instrument JOIN instrument_master ON instrument.id = instrument_master.instrumentId",
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, paylod)
      .then((res) => {
        let newData = res.data;
        setInstrumentList(newData);
      })
      .catch((err) => {
        console.log("instrument data fetching error: ", err);
      });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    // getTotalRows();
    getInstrumentData();
  }, []);

  // useEffect(() => {
  //   fetchFinalData();
  // }, [rowsPerPage, page]);

  const actions = [
    // {
    //   id: "delete",
    //   tooltip: "Delete Vendor",
    //   icon: <DeleteIcon style={{ color: "#dc3545" }} />,
    //   handler: (row)  => window.confirm('Are you sure you want to delete this?') && handleDelete(row?.id),
    // },
  ];

  const generateLetterHeadFooter = async () => {
    let htmlString = `
      <div>
        <img src=${UnitechFooter} style="width:100%; height:150px;"/>
      </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateLetterHeadHeader = async () => {
    let htmlString = `
      <div>
        <img src=${UnitechHeader} style="width:100%; height:170px;"/>
      </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [80, 1, 135, 1],
        filename: `SummaryReport.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 790, 595, 50);
      }
    }

    pdf.save();
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <br />
        <Grid container spacing={2} style={{ marginBottom: "10px" }}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={instrumentList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Instrument Name *" />
              )}
              onChange={(event, value) => {
                if (value) {
                  setInstrumentID(value.id);
                } else {
                  setInstrumentID(null);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            {" "}
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            {" "}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>{" "}
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={1}>
            {" "}
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                search();
              }}
            >
              <SearchIcon />
            </Button>{" "}
          </Grid>
        </Grid>

        <br />
        {/* <div style={{ padding: "0px", width: "100%" }}>
          <Grid container spacing={2} align="left" style={{ display: "flex" }}>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Letter Head" },
                  { id: 2, label: "Without Letter Head" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="letterHead" />
                )}
                onInputChange={(event, newInputValue) => {
                  if (
                    newInputValue === "With Letter Head" ||
                    newInputValue === ""
                  ) {
                    setLetterHead(true);
                  } else if (newInputValue === "Without Letter Head") {
                    setLetterHead(false);
                  }
                  // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Electronically Signed" },
                  { id: 2, label: "Without  Electronically Signed" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Electronically Signed" />
                )}
                onInputChange={(event, newInputValue) => {
                  console.log("newValue : ", newInputValue);
                  if (
                    newInputValue === "With Electronically Signed" ||
                    newInputValue === ""
                  ) {
                    setElectronicSign(true);
                  } else if (
                    newInputValue === "Without  Electronically Signed"
                  ) {
                    setElectronicSign(false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                size="small"
                sx={{ ml: 3 }}
                onClick={() => {
                  generatePDF();
                }}
              >
                Download PDF
              </Button>
            </Grid>
          </Grid>
        </div> */}

        <br />
        <div ref={printComponentRef} style={{ width: "100%", margin: "auto" }}>
          <Tables
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleDelete={handleDelete}
            columns={columns}
            finalData={data}
            actions={actions}
            totalRows={totalRows}
          />

          <div style={{ width: "100%" }} id="header-section"></div>
        </div>
      </TableContainer>
    </div>
  );
};

export default SummaryList;
