import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import { Button, TableContainer, Toolbar, TextField, Autocomplete, useMediaQuery, Grid } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "name",
    label: "Vendor Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },

];

const VendorMasterList = () => {
  const [page, setPage] = React.useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Vendor Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [searched, setSearched] = React.useState(false);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Vendor Name": "name",
        "Address": "address",
        "Certificate Number": "certificateNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }


    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT * from vendor_master  ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM vendor_master  ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {

    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data)
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);    
    setSearched(true);
    fetchFinalData();
    getTotalRows();
  };

  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched");
    sessionStorage.removeItem("currentPath");
    setSearchBy("Vendor Name");
    setSearchKey("");
    setSearched(false); 
  };


  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `vendor_master/${id}`)
      .then((res) => {
        toast("Vendor is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (searched==false) {
      fetchFinalData();
      getTotalRows();
    }
  }, [searched]);
  
  useEffect(() => {
    fetchFinalData();
    getTotalRows();
  }, [rowsPerPage,page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit Vendor",
      link: true,
      linkTo: (id) => `/editVendorMaster/${id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "certificate",
      tooltip: "View Certificate",
      icon: <ReceiptLongIcon />,
      handler: (row)  => window.open(row?.certificateFilePath, '_blank'),
    },
    {
      id: "scope",
      tooltip: "View Scope",
      icon: <DescriptionIcon />,
      handler: (row)  => window.open(row?.scopePath, '_blank'),
    },
    {
      id: "delete",
      tooltip: "Delete Vendor",
      disabled:!(editAccess?.includes(2) || editAccess?.includes(0)) ? true : false,
      icon: <DeleteIcon  style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' :  "#dc3545" }}   />,
      handler: (row)  => window.confirm('Are you sure you want to delete this?') && handleDelete(row?.id),
    },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 1, px:1  }}>
        <Toolbar style={{padding:0}}>
          {showComponent("add") && (
            <Button
              variant="contained"
              size="small"
              component={Link}
              to="/editVendorMaster"
              // onClick={() => {
              //   setTitle("Master/ add new user");
              // }}
            >
              <b>ADD NEW</b>
            </Button>
          )}
        </Toolbar>
        <Grid
        container
        spacing={2}
        justifyContent={"flex-start"} 
        alignItems="center"
        style={{ padding: "5px 5px" }}
      >
        <Grid item xs={6} sm={6} md={2} lg={2}
        >
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          id="combo-box-demo"
          value={searchBy}
          options={
            _userType != 3
              ? [
                  { key: "name", label: "Vendor Name" },
                  { key: "address", label: "Address" },
                  { key: "certificateNumber", label: "Certificate Number" },
                ]
              : [{ key: "name", label: "LAB Name" }]
          }
         
          renderInput={(params) => (
            <TextField {...params} label="Search By" />
          )}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}
        >
         <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          inputProps={{
            autoComplete: 'off',
          }}
          value={searchKey}
          variant="outlined"
          onChange={(e) => {
            setSearchKey(e.target.value);
            setSearched(false);
          }}
        />
        </Grid>
        <Grid item xs={6} sm={6} md={1} lg={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            sx={{height:"40px" }}
            color={searched ? "error" : "primary"}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
        </Grid>  
        </Grid>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  )
}

export default VendorMasterList