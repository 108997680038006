import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Paper } from "@mui/material";
import { BASE_URL } from "./../../global";
import Table from "react-bootstrap/Table";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

export default function TodaysStdInOut() {
  const [instrumentOut, setInstrumenOut] = React.useState([]);
  const [instrumenIn, setInstrumenIn] = React.useState([]);
  const [instrumentMissing, setInstrumentMissing] = React.useState([]);

  const getStandardOut = (event) => {
    let sqlQuery = {
      query: `SELECT instrumentId, id, expectedReturnDate, actualReturnDate FROM instrumentInOut WHERE outwordDate = CURRENT_DATE`,
    };
    let url = BASE_URL;
    axiosWithToken
      .post(url + "dynamic", sqlQuery)
      .then((res) => {
        setInstrumenOut(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStandardIn = (event) => {
    let sqlQuery = {
      query: `SELECT instrumentId, id, expectedReturnDate, actualReturnDate FROM instrumentInOut WHERE actualReturnDate = CURRENT_DATE`,
    };

    let url = BASE_URL;
    axiosWithToken
      .post(url + "dynamic", sqlQuery)
      .then((res) => {
        setInstrumenIn(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInstrumentMissing = (event) => {
    let sqlQuery={"query":`SELECT instrumentId, id, expectedReturnDate, actualReturnDate FROM instrumentInOut WHERE actualReturnDate IS NULL`}
    let url = BASE_URL;
    axiosWithToken
    .post(url + "dynamic",sqlQuery)
      .then((res) => {
        setInstrumentMissing(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStandardOut();
    getStandardIn();
    getInstrumentMissing();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Instrument out </th>
            <th>Instrument In </th>
            <th>Instrument Missing</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{instrumentOut.length}</td>
            <td>{instrumenIn.length}</td>
            <td>{instrumentMissing.length}</td>
          </tr>
        </tbody>
      </Table>
    </Paper>
  );
}