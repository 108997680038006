import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import MultiValueInputModal from "../../utils/components/multiValueInputModal";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const purposeArray = [
  { id: 1, label: "Calibration" },
  { id: 2, label: "Repairing" },
];

const CreateInstrumentInOut = () => {
  const [inputStates, setInputStates] = React.useState({
    instrumentMasterId: null,
  });
  const [instrumentList, setInstrumentList] = React.useState([]);
  const [vendorMasterData, setVendorMasterData] = React.useState([]);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const _vendorId = localStorage.getItem("vendorId");
  const _userType = localStorage.getItem("type");
  const params = useParams();

  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  function transformInputStates(inputStates) {
    // Split the instrumentMasterId into an array of IDs
    const ids = inputStates.instrumentMasterId.split(",");

    // Map each ID to a new object with the other data
    return ids.map((id) => ({
      instrumentMasterId: id,
      purpose: inputStates.purpose,
      outwordDate: inputStates.outwordDate,
      expectedReturnDate: inputStates.expectedReturnDate,
      vendorId: inputStates.VendorId,
      dispatchMode: inputStates.dispatchMode,
      remarks: inputStates.remarks,
    }));
  }

  const handleSubmit = () => {
    let url = BASE_URL;
    const transformedArray = transformInputStates(inputStates);

    axiosWithToken
      .post(url + "instrumentInOut/bulk", transformedArray)
      .then((res) => {
        let SQL_Query = {
          query: `UPDATE instrument_master SET assignedUser = ${inputStates.VendorId} WHERE id IN (${inputStates.instrumentMasterId})`,
        };
        axiosWithToken.post(BASE_URL + `dynamic`, SQL_Query);

        setTimeout(refresh, 500);
        toast("InstrumentInOut created successfully !");
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchInstrument_master = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instrument_master/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let newData = {
          ...inputStates,
          ...dataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  function getInstrumentData() {
    let SQLQuery = {
      query: `SELECT im.*, ins.name FROM instrument_master im LEFT JOIN instrument ins ON im.instrumentId = ins.id LEFT JOIN (SELECT iio1.* FROM instrumentInOut iio1 INNER JOIN (SELECT instrumentMasterId, MAX(id) AS maxId FROM instrumentInOut GROUP BY instrumentMasterId) AS max_iio ON iio1.id = max_iio.maxId) iio_max ON im.id = iio_max.instrumentMasterId WHERE iio_max.instrumentMasterId IS NULL OR (iio_max.actualReturnDate IS NOT NULL AND DATE(iio_max.actualReturnDate) <= CURRENT_DATE)`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQLQuery)
      .then((res) => {
        let newData = res.data;
        setInstrumentList(newData);
      })
      .catch((err) => {
        console.log("InstrumentInOut data fetching error: ", err);
      });
  }

  function getVendorMasterData() {
    axiosWithToken
      .get(BASE_URL + `vendor_master`)
      .then((res) => {
        let newData = res.data;
        setVendorMasterData(newData);
      })
      .catch((err) => {
        console.log("instrument data fetching error: ", err);
      });
  }

  const validateData = () => {
    let isValid = true;

    if (!inputStates?.instrumentMasterId) {
      toast.error("Please select a InstrumentInOut");
      isValid = false;
    }
    // if (!inputStates?.identificationNumber) {
    //   toast.error("Please  Enter Identification Number");
    //   isValid = false;
    // }

    return isValid;
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchInstrument_master();
  }, [params.id]);

  useEffect(() => {
    getInstrumentData();
    getVendorMasterData();
  }, []);

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Create Instrument In Out
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                multiple
                options={instrumentList}
                getOptionLabel={(option) =>
                  `${option.name}, ${option.identificationNumber}`
                }
                value={instrumentList.filter((instrument) =>
                  inputStates.instrumentMasterId
                    ? inputStates.instrumentMasterId
                        .split(",")
                        .includes(instrument.id.toString())
                    : false
                )}
                renderInput={(params) => (
                  <TextField {...params} label="InstrumentInOut Name *" />
                )}
                onChange={(event, value) => {
                  const selectedIds = value
                    .map((instrument) => instrument.id)
                    .join(",");
                  updateInputObject("instrumentMasterId", selectedIds);
                }}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={dentificationNumbertData}
                getOptionLabel={(option) => option.identificationNumber}
                value={
                  dentificationNumbertData.find(
                    (lab) =>
                      lab.identificationNumber ===
                      inputStates.identificationNumber
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Identification Number *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject(
                      "identificationNumber",
                      value.identificationNumber
                    );
                  } else {
                    updateInputObject("identificationNumber", "");
                  }
                }}
              />
            </Grid> */}

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                //   disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={purposeArray}
                getOptionLabel={(option) => option.label}
                value={
                  purposeArray?.find((lab) => lab.id === inputStates.purpose) ||
                  null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Purpose" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("purpose", value.id);
                  } else {
                    updateInputObject("purpose", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="Outword Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.outwordDate
                      ? new Date(inputStates.outwordDate)
                      : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "outwordDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="Expected Return Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.expectedReturnDate
                      ? new Date(inputStates.expectedReturnDate)
                      : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "expectedReturnDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={
                  _userType == 3
                    ? vendorMasterData?.filter(
                        (vendor) => vendor.id == _vendorId
                      )
                    : vendorMasterData
                }
                getOptionLabel={(option) => option.name}
                value={
                  vendorMasterData.find(
                    (lab) => lab.id === inputStates.VendorId
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Vendor Name " />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("VendorId", value.id);
                  } else {
                    updateInputObject("VendorId", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Dispatch Mode"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                value={inputStates.dispatchMode || ""}
                onChange={(e) => {
                  updateInputObject("dispatchMode", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="Remark "
                size="small"
                rows={3}
                multiline
                maxRows={5}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                value={inputStates.remarks || ""}
                onChange={(e) => {
                  updateInputObject("remarks", e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
              sx={{ m: 0 }}
              onClick={() => {
                if (!validateData()) return;
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </div>
    </div>
  );
};

export default CreateInstrumentInOut;
