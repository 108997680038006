import { Autocomplete, Button, Grid, Input, Paper, TextField, Toolbar, Typography } from '@mui/material'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from 'react'
import { BASE_URL } from "../../global";
import axiosWithToken from '../../utils/components/axiosTokenConfig';

const EditVendorMaster = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const params = useParams();

  const updateInputObject = (key, value, q) => {
      let newInputObject = {
        ...inputStates,
      };    
      newInputObject[key] = value;    
      setInputStates({ ...newInputObject });
    };

    const handleSubmit =()=>{
      const updatedPayload = {
        ...inputStates,
        startDate: moment(inputStates.startDate).format("YYYY-MM-DD"),
        endDate: moment(inputStates.endDate).format("YYYY-MM-DD"),
      };

      let url = BASE_URL;
      if (params.id) {
        axiosWithToken
          .patch(url + `vendor_master/${params.id}`, updatedPayload)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("Vendor edit successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
  
      } else {
        axiosWithToken
          .post(url + "vendor_master", inputStates)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("Vendor created successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
  
      }

    }

    const fetchVendor_master = (inpt) => {
      let url = BASE_URL;
      axiosWithToken
        .get(url + `vendor_master/${params.id}`)
        .then((res) => {
          let dataObject = res.data[0];
  
          let newData = {
            ...inputStates,
            ...dataObject,
           
          };
            dataObject && setInputStates(newData);
         
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    };


    const handleFileUpload = (event, fieldName, q) => {
      const file = event.target.files[0];
      const data = new FormData();
      data.append("fileToUpload", file);
    
      axiosWithToken
        .post(
          BASE_URL + `fileUpload/fileUpload.php`,
          data
        )
        .then((res) => {
          if (res.status === 200) {
            // Assuming `q` is a constant string specific to the field
            updateInputObject(fieldName, res.data, q);
            toast.success("File Uploaded Successfully!", {
              position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something Went Wrong!");
        });
    };
    

    var refresh = () => {
      window.location.reload(false);
    };

useEffect(()=>{
 if(params.id)fetchVendor_master()
},[params.id])

return (
  <div>
          <div>
   <Paper sx={{ mt: 2, p: 2 }}>
   <Typography variant="h6" component="h6" style={{ float: "left" }}>
      Create/Update Vendor Master
    </Typography>
    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
     
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <TextField
          id="outlined-basic"
          label="Vendor Name  *"
          size="small"
          fullWidth
          inputProps={{
              autoComplete: 'off',
            }}
          variant="outlined"
          value={inputStates.name || ""}
          onChange={(e) => {
            updateInputObject("name", e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3}>
        <TextField
          id="outlined-basic"
          label="Address *"
          size="small"
          fullWidth
          inputProps={{
              autoComplete: 'off',
            }}
          variant="outlined"
          value={inputStates.address || ""}
          onChange={(e) => {
            updateInputObject("address", e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <TextField
          id="outlined-basic"
          label="Certificate Number *"
          size="small"
          fullWidth
          inputProps={{
            autoComplete: 'off',
          }}
          variant="outlined"
          value={inputStates.certificateNumber || ""}
          onChange={(e) => {
            updateInputObject("certificateNumber", e.target.value);
          }}
        />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={3} lg={3}>
        <TextField
          id="outlined-basic"
          label="Scope *"
          size="small"
          fullWidth
          variant="outlined"
          value={inputStates.scope || ""}
          onChange={(e) => {
            updateInputObject("scope", e.target.value);
          }}
        />
      </Grid> */}

      <Grid item xs={12} sm={12} md={3} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="Start Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.startDate
                      ? new Date(inputStates.startDate)
                      : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "startDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="End Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.endDate
                      ? new Date(inputStates.endDate)
                      : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "endDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
      
      <Grid item xs={12} sm={12} md={3} lg={3} textAlign={"left"}>
          <Input
            id="file-upload-input1"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleFileUpload(e,"certificateFilePath","dynamicQValue1")}
          />
          <label htmlFor="file-upload-input1">
            <Button variant="contained" component="span">
              Upload Certificate 
            </Button>
          </label>
          <Typography variant="body2" color="textSecondary">
            Select a file to upload
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} textAlign={"left"}>
          <Input
            id="file-upload-input"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleFileUpload(e,"scopePath","dynamicQValue2")}
          />
          <label htmlFor="file-upload-input">
            <Button variant="contained" component="span">
              Upload Scope
            </Button>
          </label>
          <Typography variant="body2" color="textSecondary">
            Select a file to upload
          </Typography>
        </Grid>
    </Grid>
    <Toolbar style={{ padding: "0px", overflow: "auto" }}>
    <Button
          variant="contained"
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
               handleSubmit()             
          }}
        >
      {params.id ? "Update":"Save"}
        </Button>
    </Toolbar>
   </Paper>
  </div>
  </div>
)
}

export default EditVendorMaster